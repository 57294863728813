@font-face {
  font-family: "Acorn";
  src: url("/public/fonts/Acorn-Regular.otf") format("opentype"); /* Definición de Acorn-Thin */
  font-weight: 300; /* Peso para Acorn-Thin */
}

@font-face {
  font-family: "Acorn";
  src: url("/public/fonts/Acorn-Bold.otf") format("opentype"); /* Definición de Acorn-Bold */
  font-weight: bold; /* Peso para Acorn-Bold */
}

.pageGlobalStyle {
  font-family: "Acorn", sans-serif; /* Usa Acorn como fuente principal */
  background: rgb(242, 242, 242);
  background: -moz-radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(217, 0, 0, 0.1292892156862745) 0%,
    rgba(242, 242, 242, 1) 50%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(217, 0, 0, 0.1292892156862745) 0%,
    rgba(242, 242, 242, 1) 50%
  );
  background: radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(217, 0, 0, 0.1292892156862745) 0%,
    rgba(242, 242, 242, 1) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f2f2",endColorstr="#f2f2f2",GradientType=1);
}

/* Navbar */
.navbarr {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: 10px 0px;
  background-color: #f2f2f2;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.navbarr-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Logo a la izquierda */
.navbarr-logo img {
  height: 50px;
  cursor: pointer;
}

/* Enlaces de navegación al centro */
.navbarr-links {
  align-items: center;
  list-style: none;
  display: flex;
  gap: 30px;
  margin: 0;
}

.navbarr-links li a {
  text-decoration: none;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbarr-links li a:hover {
  color: #007bff;
  border-bottom: 2px solid #007bff;
}
.navbarr-links li div a:hover {
  border: none;
}

/* Botón "Empezar" con gradiente */
.navbarr-button .btn-empezar {
  padding: 10px 20px;
  background: linear-gradient(90deg, #3440ec 0%, #c52727 100%);
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  border-radius: 25px;
}

/* Estilos para el menú hamburguesa */
.navbar-hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}
.burger-titles {
  font-weight: bold;
  font-size: 20px;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 3px 0;
  transition: all 0.3s ease;
}

/* Estilos adicionales para la clase 'body' */
.body {
  padding: 20px;
}

.contenido-centro {
  background: rgb(242, 242, 242);
  background: -moz-radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(217, 0, 0, 0.1292892156862745) 0%,
    rgba(242, 242, 242, 1) 50%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(217, 0, 0, 0.1292892156862745) 0%,
    rgba(242, 242, 242, 1) 50%
  );
  background: radial-gradient(
    circle,
    rgba(242, 242, 242, 1) 0%,
    rgba(217, 0, 0, 0.1292892156862745) 0%,
    rgba(242, 242, 242, 1) 50%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f2f2",endColorstr="#f2f2f2",GradientType=1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 0px 50px 0px;
  height: 100vh;
  min-height: 700px;
  gap: 20px;
}

.contenido-centro h1 {
  font-size: 50px;
  font-weight: normal;
  margin: 0px;
}
.contenido-centro .wordWrapper {
  position: relative;
}

.animTextWrap {
  min-width: 200px;
  width: max-content;
  height: 50px;
  overflow: hidden;
  position: relative;
  text-align: left;
}

.animTextWrap h1{
  margin-top: -5px;
  font-weight: bold;
  transition: all 0.5s;
  /* animation: slideUpText 0.5s ease-in; */
}

.animText{
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  text-align: left;
}
/* 
@keyframes slideUpText {
  from {
    top: 45px;
  }
  to {
    top: -5px;
  }
} */

.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.animImgWrap{
  position: absolute;
  top:-150px;
  left: 68px;
  z-index: -1;
  width: 216px;
  height: 216px;
  overflow: hidden;
}

.animImg{
  margin-top: 0px;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.headingImg {
  background-color: #ffffff50;
  border-radius: 50%;
}

.contenido-centro h2 {
  margin: 0px;
  font-size: 50px;
}
.MyFastVisa-home {
  font-family: "Acorn", sans-serif;
}
.yaSea {
  font-size: 35px;
  margin: 0px 250px;
}

.nuestrosServicios {
  font-size: 40px;
  text-align: center;
  background: linear-gradient(90deg, #3440ec 0%, #c52727 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  padding-top: 1rem;
}

/* Contenedor de las tarjetas */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 80px;
  margin: 20px 80px 0px 80px;
}

/* Estilos de cada tarjeta */
.cards {
  width: 260px;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s;
}

.cards:hover {
  transform: scale(1.02);
}

.numberSpan {
  color: #3440ec;
}
.somePeople {
  font-size: 40px;
  padding: 60px 0px;
  text-align: center;
}

.opinion-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  gap: 50px;
  margin: 0px 140px;
}
.opinion-cards {
  width: 300px;
  height: auto;
}

.faq-container {
  display: flex;
  justify-content: center;
  padding: 100px 0px;
  gap: 50px;
}
.faq1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faq2 {
  width: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
}
.faq-title {
  font-size: 35px;
}
.div-transition {
  align-items: start !important;
  background-color: #ebebeb;
  padding: 10px;
  margin-right: 20px;
  border-radius: 15px;
  color: black;
}
.doubts {
  font-size: 13px;
}
.contact-button {
  font-size: 18px;
  padding: 10px 20px;
  background: transparent;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
  color: #000;
}

.contact-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 25px;
  padding: 2px;
  background: linear-gradient(90deg, #3440ec 0%, #c52727 100%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.banner-container {
  display: flex;
  justify-content: center;
  padding: 30px 0px;
}
.banner-section {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 70vw;
  height: 70vh;
}

/* Estilo para la imagen de fondo */
.bannerAcerca {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  background-color: #000000;
  border-radius: 30px;
}

/* Estilo para el logo dentro de la imagen */
.logoAcerca {
  z-index: 1;
  width: 150px;
  height: auto;
}

/* Estilo para el texto debajo del logo */
.textoDebajoLogo {
  z-index: 1;
  margin-top: 20px;
  padding: 0px 5px;
  font-size: 20px;
  color: white;
}

.content-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 130px;
}

.text-container {
  flex: 1;
  text-align: center;
}
.text-content {
  font-size: 30px;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.side-image {
  width: 350px;
  height: auto;
}

.span {
  background: linear-gradient(90deg, #3440ec 0%, #c52727 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}
.entendemos {
  text-align: center;
  font-size: 25px;
  padding: 0px 400px;
}
.textAndIcon-section-container {
  width: 100%;
  margin: 100px 0px;
  display: flex;
  justify-content: center;
}
.textAndIcon-section {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
.texto-section {
  width: 100%;
  text-align: center;
  flex: 1;
  font-size: 25px;
}
.icon-section {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
}

.icon-item {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 5px;
}

/* Estilos para el modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}

.modal-content {
  background-color: white !important;
  padding: 20px;
  border-radius: 25px !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 90%;
}

.close-modal-button {
  background-color: #4caf50; /* Color de fondo del botón cerrar */
  color: white; /* Color del texto */
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.icon-image {
  width: 25px;
  height: auto;
  margin-bottom: 10px;
}

.icon-text {
  font-size: 25px;
  color: #000;
}

.lanzamienzo {
  margin: 30px 350px;
  font-size: 25px;
  text-align: center;
}

.image-alejo-container {
  margin: 100px 0px;
  display: flex;
  justify-content: center;
}
.image-alejo {
  width: 25vw;
}

.agencias {
  font-weight: normal;
  padding: 30px 0px 0px 0px;
  text-align: center;
  font-size: 40px;
}
.ofrecemos {
  text-align: center;
  font-size: 16px;
}
.complete {
  margin: 0vh 38vw;
  text-align: center;
  font-size: 16px;
}

.form-container {
  margin: 50px 0px;
}
.contact-form {
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 20px 20px;
  gap: 15px;
  border-radius: 35px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 15px;
}

.input-container {
  position: relative;
  flex: 1;
  margin-bottom: 15px;
}
.inputTrabajo {
  text-align: center;
  padding: 10px;
}

.icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.submit-button {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: #3440ec;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #2f39cd;
}
.enviar-icon {
  padding: 0px 10px;
}

.contactanos {
  font-weight: normal;
  font-size: 40px !important;
}

.name-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
/* Estilo para el botón seleccionado */
.input-button.selected {
  background-color: #4caf50; /* Color de fondo cuando está seleccionado */
  color: white; /* Color del texto */
}

/* Estilo para el mensaje de éxito */
.success-message {
  margin-top: 20px;
  padding: 15px;
  background-color: #e0ffe0; /* Color de fondo suave */
  color: #2e702e; /* Color del texto */
  border: 1px solid #4caf50; /* Borde verde */
  border-radius: 5px; /* Bordes redondeados */
  text-align: center; /* Centrar el texto */
}

.name-container {
  flex: 1;
  margin-right: 10px;
}

.name-container:last-child {
  margin-right: 0;
}

.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
}

.text-blocks {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 40%;
  gap: 30px;
}

.text-block {
  width: 45%;
  background-color: #ebebeb;
  padding: 10px;
  border-radius: 25px;
}
.text-block-contactanos {
  width: 45%;
  padding: 10px;
  margin: 10px 1%;
  border-radius: 5px;
}
.form-container-contactanos {
  width: 40%;
}

.input-group {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.input-group input {
  width: calc(33.33% - 10px);
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 15px;
}
.input-container textarea {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
  resize: none;
}

.btn-submit {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.requisitos-container-total {
  width: 100%;
  display: flex;
  justify-content: center;
}
.requisitos-container {
  padding: 20px;
  margin: 100px 0px 0px 0px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}
.input-button {
  width: 100%;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ccc;
  background-color: #ccc;
  color: white;
  cursor: pointer;
}

.input-button:hover {
  background-color: #007bff;
}

.requisitos-titulo {
  margin-bottom: 10px;
}
.requisitos-titulo p {
  font-size: 30px;
}

.requisitos-contenido {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}
.requisitos-img {
  width: 100%;
}
.requisitos-img img {
  width: 100%;
}
.requisitos-icons {
  width: 20px;
}

.requisitos-textos {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.texto-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.requisitos-boton {
  margin-top: 20px;
}
.requisitos-text {
  margin: 0px;
}

/* Footer */

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding: 20px 200px;
  background-color: #ebebeb;
  border-top: 1px solid #e5e5e5;
}

.footer-section {
  flex: 1;
  padding: 0 20px;
}

.footer-logo {
  height: 60px;
}

.footer-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section li {
  margin: 5px 0;
  cursor: pointer;
  transition: color 0.3s;
}

.footer-section li:hover {
  color: #007bff;
}

.li-footer {
  padding-top: 20px;
}
.logo-section {
  width: 100%;
}
.products-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.company-section {
  width: 100%;
}
hr {
  margin: 0px 200px !important;
  padding: 0px;
}
.lastFooter {
  margin: 0px;
  padding: 5px 0px;
  text-align: center;
  font-size: 11px;
  background-color: #ebebeb;
}

.link {
  color: black;
}
.link:hover {
  color: #007bff;
}
.social-container {
  display: flex;
  gap: 10px;
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  /* Navbar */
  /* Estilos adicionales para el menú hamburguesa desde la derecha */
  .navbarr-links {
    position: fixed;
    right: -100%;
    top: 0;
    height: 100%;
    width: 300px;
    background-color: #f2f2f2;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 999;
  }

  .navbarr-links.active {
    right: 0;
  }

  .navbar-hamburger {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    z-index: 1000;
  }

  .line {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
    transition: all 0.3s ease;
  }

  /* Efecto de transformación cuando el menú está abierto */
  .line.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .line.open:nth-child(2) {
    opacity: 0;
  }

  .line.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .navbarr-button {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .contenido-centro {
    height: 85vh;
    padding: 0px 1px;
    min-height: 600px;
  }

  .contenido-centro h1 {
    font-size: 35px;
    /* padding: 0px 25px; */
  }
  .contenido-centro h2 {
    margin: 0px;
    font-size: 35px;
    font-family: "DM Sans", sans-serif;
  }

  .animTextWrap{
    min-width: 150px;
  }

  .animTextWrap h1{
    padding: 0;
  }
  .animTextWrap {
    padding-left: 1rem;
    text-align: center;
    height: 35px;
    width: 100%;
  }

  .animText{
    left: 50px;
  }

  .titleWrapper{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .animImgWrap{
    position: relative;
    top: 0;
    left: 0;
  }

  .yaSea {
    font-weight: normal;
    margin: 0px 0px;
    font-size: 20px;
  }

  .nuestrosServicios {
    font-size: 25px;
    text-align: center;
    background: linear-gradient(90deg, #3440ec 0%, #c52727 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
  .card-container {
    gap: 80px;
  }
  .cards {
    width: 80vw;
  }
  .opinion-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    gap: 50px;
    margin: 0px 140px;
  }
  .opinion-cards {
    width: 80vw;
    height: auto;
  }
  .somePeople {
    font-size: 25px;
    padding: 60px 10px;
    text-align: center;
  }
  .requisitos-container {
    padding: 20px;
    margin: 100px 15px 0px 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #ebebeb;
    align-items: center;
    border-radius: 25px;
  }
  .requisitos-titulo p {
    font-size: 25px;
  }

  .requisitos-contenido {
    flex-direction: column;
  }
  .faq-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 100px 0px;
    gap: 50px;
  }
  .faq1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .faq2 {
    width: 100%;
    padding: 0px 15px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
  .div-transition {
    align-items: start !important;
    width: 100%;
    padding: 15px;
  }
  .faq-title {
    font-size: 25px;
  }
  .doubts {
    font-size: 15px;
  }
  .banner-section {
    width: 85vw;
    height: 80vh;
  }
  .content-section {
    flex-direction: column;
    margin: 150px 25px;
  }

  .text-container {
    text-align: left;
  }
  .text-content {
    font-size: 25px;
  }

  .side-image {
    width: 100%;
  }
  .entendemos {
    font-size: 20px;
    padding: 0px 40px;
  }
  .textAndIcon-section {
    width: 100%;
    flex-direction: column;
    gap: 25px;
  }
  .texto-section {
    padding: 0px 30px;
    font-size: 20px;
  }
  .icon-section {
    padding: 0px 20px;
  }

  .icon-text {
    font-size: 20px;
    color: #000;
  }
  .lanzamienzo {
    margin: 30px 30px;
    font-size: 20px;
  }
  .image-alejo-container {
    margin: 100px 0px;
    display: flex;
    justify-content: center;
  }
  .image-alejo {
    width: 85vw;
  }
  .ofrecemos {
    text-align: left;
    font-size: 18px;
    padding-left: 20px;
  }
  .complete {
    margin: 30px 0px 0px 0px;
    text-align: left;
    font-size: 18px;
    padding-left: 20px;
  }
  .form-container {
    margin: 25px 15px 50px 15px;
  }
  .contact-form {
    background-color: #ebebeb;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    padding: 20px 20px;
    gap: 15px;
    border-radius: 35px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .form-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .input-container {
    position: relative;
    flex: 1;
  }

  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
  }

  input {
    width: 100%;
    padding: 10px 10px 10px 40px;
    border: 1px solid #ccc;
    border-radius: 15px;
  }

  .submit-button {
    margin-top: 20px;
    padding: 10px;
    background-color: #3440ec;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
  }

  .submit-button:hover {
    background-color: #2f39cd;
  }
  .enviar-icon {
    padding: 0px 10px;
  }

  .contact-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
  }

  .text-blocks {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
  }

  .text-block {
    width: 100%;
    background-color: #ebebeb;
    padding: 10px;
    border-radius: 25px;
  }
  .text-block-contactanos {
    width: 100%;
    padding: 10px;
    margin: 10px 1%;
    border-radius: 5px;
  }
  .form-container-contactanos {
    width: 100%;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .btn-submit {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }

  .btn-submit:hover {
    background-color: #0056b3;
  }

  .modal-content {
    margin: 0px 20px;
  }

  .footer {
    flex-direction: column;
    padding: 20px 20px;
    gap: 30px;
  }
}
